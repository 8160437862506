html,
body,
#root,
.App {
  margin: 0;
  height: 100%;
  //background: linear-gradient(#1a202c, #943c22);
  /* background: #1a202c; */
  background: #121212;
}
