.item-group {
    --xyz-translate-y: -350%;
    --xyz-ease: cubic-bezier(0.5, -1.5, 0.5, 1.5);
}

:root {
    color-scheme: dark;
    overflow: hidden;
}

u {
    text-underline-offset: 6px;
    text-decoration-thickness: 4px;
    text-decoration-color: #525252;
}

.rc-controls > li > button {
    color: black !important;
}
